var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "mt-3" },
    [
      _vm.loading
        ? _c(
            "div",
            { staticClass: "text-center" },
            [
              _c("v-progress-linear", {
                attrs: { color: "primary", indeterminate: "" },
              }),
            ],
            1
          )
        : _vm._e(),
      !_vm.loading
        ? _c(
            "v-tabs",
            {
              attrs: {
                grow: "",
                dark: "",
                height: "35",
                "background-color": "primary",
              },
              model: {
                value: _vm.tab,
                callback: function ($$v) {
                  _vm.tab = $$v
                },
                expression: "tab",
              },
            },
            [
              _c(
                "v-tab",
                {
                  attrs: { disabled: this.hourObj.id ? _vm.tab !== 0 : false },
                },
                [
                  _c("v-icon", { attrs: { color: "white" } }, [
                    _vm._v("mdi-calendar-check"),
                  ]),
                  _c("span", { staticClass: "ml-2 text-capitalize" }, [
                    _vm._v(_vm._s(_vm.$t("message.timer.hourlogging"))),
                  ]),
                ],
                1
              ),
              _c(
                "v-tab",
                {
                  attrs: { disabled: this.hourObj.id ? _vm.tab !== 1 : false },
                },
                [
                  _c("v-icon", { attrs: { color: "white" } }, [
                    _vm._v("mdi-calendar-remove"),
                  ]),
                  _c("span", { staticClass: "ml-2 text-capitalize" }, [
                    _vm._v(_vm._s(_vm.$t("message.timer.nonBillable"))),
                  ]),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      !_vm.loading
        ? _c(
            "v-card-text",
            { staticClass: "pa-1 mt-1" },
            [
              _c("Slider", {
                attrs: { timeline: _vm.hourlogSingleDayRecords.timeLine },
              }),
            ],
            1
          )
        : _vm._e(),
      !_vm.loading
        ? _c(
            "v-tabs-items",
            {
              model: {
                value: _vm.tab,
                callback: function ($$v) {
                  _vm.tab = $$v
                },
                expression: "tab",
              },
            },
            [
              _c(
                "v-tab-item",
                [
                  _vm.tab === 0
                    ? _c("NormalHours", {
                        attrs: {
                          hourObj: _vm.hourObj,
                          listItems: _vm.normalHoursListItems,
                          isWeekLocked: _vm.weekLockStatus,
                          backLink: _vm.backLink,
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "v-tab-item",
                [
                  _vm.tab === 1
                    ? _c("AbsenceHours", {
                        attrs: {
                          hourObj: _vm.hourObj,
                          listItems: _vm.absenceHoursListItems,
                          isWeekLocked: _vm.weekLockStatus,
                          backLink: _vm.backLink,
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      (_vm.tab === 0
        ? _vm.getAllNormalHourLogs.length > 0
        : _vm.getAllNonbillableHourLogs.length > 0) && !_vm.loading
        ? _c(
            "v-card",
            { staticClass: "mt-3", attrs: { flat: "" } },
            [
              _vm.isshowTitle
                ? _c("v-card-title", { staticClass: "pa-1 py-0" }, [
                    _vm._v(_vm._s(_vm.$t("message.calendar.otherHourEntries"))),
                  ])
                : _vm._e(),
              _c(
                "v-card-text",
                { staticClass: "px-1 py-0" },
                [
                  _vm._l(
                    _vm.tab === 0
                      ? _vm.getAllNormalHourLogs
                      : _vm.getAllNonbillableHourLogs,
                    function (hourRecord, index) {
                      return [
                        _vm.hourObj.id !== hourRecord.id
                          ? _c(
                              "v-list",
                              {
                                key: hourRecord.id + "_" + index,
                                staticClass: "pa-0 py-1",
                              },
                              [
                                _c(
                                  "v-list-item",
                                  {
                                    staticClass: "pa-0 custom_box-shadow",
                                    on: {
                                      click: function ($event) {
                                        return _vm.getSingleRecordHandler(
                                          hourRecord.id
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "v-list-item-avatar",
                                      {
                                        staticClass: "mr-2",
                                        staticStyle: { "border-radius": "4px" },
                                        attrs: {
                                          color: "primary my-0",
                                          tile: "",
                                          size: "65",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "text-center mb-0 white--text subtitle-2",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getStartEndTimes(hourRecord)
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "v-list-item-content",
                                      {
                                        staticClass: "pa-1",
                                        staticStyle: { background: "#fff" },
                                      },
                                      [
                                        _c(
                                          "v-list-item-title",
                                          {
                                            staticClass:
                                              "h6 mb-1 font-weight-medium",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getProjectName(
                                                  hourRecord.project_id
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "v-list-item-title",
                                          { staticClass: "subtitle-2" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getWorktypeName(
                                                  hourRecord.work_id
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-list-item-action",
                                      { staticClass: "my-0" },
                                      [
                                        _c(
                                          "v-list-item-action-text",
                                          [
                                            _c(
                                              "v-chip",
                                              {
                                                staticClass: "mr-1 mt-1",
                                                attrs: {
                                                  color: "#2196f3",
                                                  small: "",
                                                  dark: "",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getHours(hourRecord)
                                                  )
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm.canDelete(hourRecord.state)
                                          ? _c(
                                              "v-btn",
                                              {
                                                attrs: { icon: "" },
                                                on: {
                                                  click: function ($event) {
                                                    $event.stopPropagation()
                                                    return _vm.deleteHourEntry(
                                                      hourRecord.id
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    attrs: {
                                                      color: "red lighten-1",
                                                    },
                                                  },
                                                  [_vm._v("mdi-delete-circle")]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    }
                  ),
                ],
                2
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }